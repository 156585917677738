import 'jquery-slim';
import 'jquery-ujs';
import 'bootstrap';
import 'accounting';
import '@gitlab/svgs/dist/icons.svg';

import setConfigs from '@gitlab/ui/dist/config';
import './sentry';

import initNavBar from '~/javascripts/navigation';
import initSideBar from '~/javascripts/sidebar/init_sidebar';
import initAlertHandler from '~/javascripts/alert_handler';
import { initTracking } from '~/javascripts/tracking';
import initValidation from '~/javascripts/forms/validation/init_validation';

require.context('../images', true);

setConfigs();
initAlertHandler();
initTracking();
initValidation();

document.addEventListener('DOMContentLoaded', () => {
  initNavBar(document.getElementById('js-navigation'));
  initSideBar();
});
