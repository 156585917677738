<script>
import {
  GlIcon,
  GlNavItemDropdown,
  GlNav,
  GlNavItem,
  GlDropdownItem,
  GlDropdownDivider,
} from '@gitlab/ui';
import Tracking from '~/javascripts/tracking';
import {
  TRACKING_ACTIONS,
  TRACKING_LABELS,
  TRACKING_CATEGORIES,
} from '~/javascripts/tracking/constants';
import { SUBMIT_FEEDBACK_URL, LICENSING_FAQ_URL, CONTACT_SUPPORT_URL } from '../constants';

export default {
  name: 'NavBarItems',
  components: {
    GlIcon,
    GlNavItemDropdown,
    GlNav,
    GlNavItem,
    GlDropdownItem,
    GlDropdownDivider,
  },
  inject: {
    isSignedIn: 'isSignedIn',
    hasSubscriptions: 'hasSubscriptions',
    customerFullName: {
      default: '',
    },
    hasBillingAccount: {
      default: false,
    },
    contactUsUrl: 'contactUsUrl',
    subscriptionsPath: 'subscriptionsPath',
    invoicesPath: 'invoicesPath',
    accountDetailsPath: 'accountDetailsPath',
    paymentMethodsPath: 'paymentMethodsPath',
    signOutPath: 'signOutPath',
    signInPath: 'signInPath',
  },
  created() {
    this.navClasses = {
      'navbar-nav': true,
      'gl-ml-auto': true,
      'gl-flex-direction-row': this.hasBillingAccount,
    };
    this.navItemClasses = {
      'gl-font-sm': true,
      'gl-font-weight-bold': true,
      'gl-px-3!': this.hasBillingAccount,
    };
    this.navItemDropdownItemClasses = {
      ...this.navItemClasses,
      'gl-display-flex': true,
      'gl-align-items-center': true,
    };
  },
  methods: {
    trackEvent(label) {
      const tracking = new Tracking();
      tracking.event(TRACKING_CATEGORIES.webstore, TRACKING_ACTIONS.clickLink, { label });
    },
  },
  SUBMIT_FEEDBACK_URL,
  LICENSING_FAQ_URL,
  CONTACT_SUPPORT_URL,
  TRACKING_LABELS,
  glNavItemAttributes: {
    role: 'menuitem',
  },
};
</script>

<template>
  <gl-nav :class="navClasses" role="menubar">
    <template v-if="isSignedIn">
      <gl-nav-item
        v-if="!hasBillingAccount"
        :class="navItemClasses"
        :href="subscriptionsPath"
        :link-attrs="$options.glNavItemAttributes"
        >Manage purchases</gl-nav-item
      >
      <gl-nav-item
        v-if="!hasBillingAccount"
        :class="navItemClasses"
        :href="invoicesPath"
        :link-attrs="$options.glNavItemAttributes"
        >View invoices</gl-nav-item
      >
      <gl-nav-item-dropdown
        :toggle-class="navItemDropdownItemClasses"
        right
        data-testid="info"
        @toggle="trackEvent($options.TRACKING_LABELS.headerInfo)"
      >
        <template #button-content>
          <gl-icon name="question" class="mr-1" variant="subtle" />
          <gl-icon :size="12" name="chevron-lg-down" />
        </template>
        <gl-dropdown-item class="gl-font-base" :href="$options.LICENSING_FAQ_URL"
          >Frequently asked questions</gl-dropdown-item
        >
        <gl-dropdown-item class="gl-font-base" :href="$options.CONTACT_SUPPORT_URL"
          >Support</gl-dropdown-item
        >
        <gl-dropdown-item class="gl-font-base" :href="contactUsUrl">Contact us</gl-dropdown-item>
        <gl-dropdown-divider />
        <gl-dropdown-item class="gl-font-base" :href="$options.SUBMIT_FEEDBACK_URL"
          >Submit feedback</gl-dropdown-item
        >
      </gl-nav-item-dropdown>
      <gl-nav-item-dropdown
        :toggle-class="navItemDropdownItemClasses"
        right
        data-testid="profile"
        @toggle="trackEvent($options.TRACKING_LABELS.headerProfile)"
      >
        <template #button-content>
          <span class="mr-1">My profile</span>
          <gl-icon :size="12" name="chevron-lg-down" />
        </template>
        <gl-dropdown-item class="gl-font-base font-weight-bold">
          <strong>{{ customerFullName }}</strong>
        </gl-dropdown-item>
        <gl-dropdown-divider />
        <gl-dropdown-item v-if="hasBillingAccount" class="gl-font-base" href="/"
          >My billing account</gl-dropdown-item
        >
        <gl-dropdown-item class="gl-font-base" :href="accountDetailsPath"
          >Profile settings</gl-dropdown-item
        >
        <gl-dropdown-item
          v-if="!hasBillingAccount && hasSubscriptions"
          class="gl-font-base"
          :href="paymentMethodsPath"
          >Payment methods</gl-dropdown-item
        >
        <gl-dropdown-divider />
        <gl-dropdown-item class="gl-font-base" :href="signOutPath" data-method="delete"
          >Sign out</gl-dropdown-item
        >
      </gl-nav-item-dropdown>
    </template>
    <template v-else>
      <gl-nav-item
        data-testid="navbar-sign-in"
        :class="navItemClasses"
        :href="signInPath"
        :link-attrs="$options.glNavItemAttributes"
        >Sign in</gl-nav-item
      >
    </template>
  </gl-nav>
</template>
